import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from '../hooks/auth'
import axios from '../lib/axios'
import Pusher from 'pusher-js'
import { includes, isNil, split, trim } from 'lodash'

const StateContext = createContext()

export const ContextProvider = ({ children }) => {
    const { user } = useAuth({ middleware: 'guest' })
    const [currency, setCurrency] = useState(() => {
        if(localStorage.getItem('currency') !== null) {
            return JSON.parse(localStorage.getItem('currency'))
        } else {
            return []
        }
    })
    const [currencyCode, setCurrencyCode] = useState(() => {
        if (localStorage.getItem('currency_code') !== null) {
            return localStorage.getItem('currency_code')
        } else {
            return "USD"
        }
    })
    const [currencySymbol, setCurrencySymbol] = useState(() => {
        if (localStorage.getItem('currency_symbol') !== null) {
            return localStorage.getItem('currency_symbol')
        } else {
            return "$"
        }
    })
    const config = {
        headers: {
            Authorization: `Bearer ${user?.plain_text_token}`,
            'Preferred-Language': localStorage.getItem('i18nextLng'),
            'Preferred-Currency': currencyCode
        },
    }

    const configFile = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${user?.plain_text_token}`,
            'Preferred-Language': localStorage.getItem('i18nextLng'),
            'Preferred-Currency': currencyCode
        },
    }

    useEffect(() => {
        if(user && !isNil(user)) {
            localStorage.setItem('client_id', user?.client_id)
            localStorage.setItem('client_timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)

            const clientType = split(user?.client_type, ',').map((type) => trim(type))
            setClientType(clientType)
            setClientIsBroker(includes(clientType, 'broker'))
            setClientIsBrokerOnly(clientType.length == 1 && includes(clientType, 'broker'))
        }
    }, [user])

    /* const pusher = new Pusher(process.env.REACT_APP_PUSHER, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        encrypted: true,
    })
 */
    /* state */
    const [companies, setCompanies] = useState([])
    const [users, setUsers] = useState([])
    const [sites, setSites] = useState([])
    const [roles, setRoles] = useState([])
    const [allSites, setAllSites] = useState([])
    const [choosesite, setChoosesite] = useState(false)
    const [company_id, setCompany_id] = useState('')
    const [performedFirstTimeRequestRetrieval, setPerformedFirstTimeRequestRetrieval] = useState(false)
    const [extended, setExtended] = useState(true)
    const [hiddenDailyBoard, setHiddenDailyBoard] = useState(true)
    const [hiddenAdminView, setHiddenAdminView] = useState(true)
    const [hiddenUserManagment, setHiddenUserManagment] = useState(true)
    const [hiddenProduction, setHiddenProduction] = useState(true)
    const [hiddenInventory, setHiddenInventory] = useState(true)
    const [total, setTotal] = useState(0)
    const [totalPendingProductionOrders, setTotalPendingProductionOrders] = useState(0)
    const [palletTypes, setPalletTypes] = useState([])
    const [clientType, setClientType] = useState([])
    const [clientIsBroker, setClientIsBroker] = useState(false)
    const [clientIsBrokerOnly, setClientIsBrokerOnly] = useState(false)
    const [features, setFeatures] = useState({})
    const [permissions, setPermissions] = useState([])
    const [logisticsTypes, setLogisticsTypes] = useState([])
    const [creditTerms, setCreditTerms] = useState([])

    const [menuItems, setMenuItems] = useState([])

    useEffect(() => {
        if (user?.role === "master_admin") {
            getPermissions()
            getCompanies()
            getUsers()
            getRoles()
        }
        if (user?.role === "office_manager") {
            getUsers()
            getRoles()
        }
        if (user) {
            getAllSites()
            getSites()
            getMenuItems()
            getPalletTypes()
            getLogisticsTypes()
            getFeatures()
            getCreditTerms()
        }
    }, [user])

    const getMenuItems = async () => {
        await axios
            .get(`/api/sidebar-menu`, config)
            .then(res => {
                const items = res.data
                setMenuItems(items)
            })
    }

    const getCompanies = async () => {
        await axios
            .get(`/api/companies`, config)
            .then(res => {
                const companies = res.data
                setCompanies(companies)
            })
    }

    const getUsers = async (role, company, site) => {
        await axios
            .get(`/api/users?role=${role}&company_id=${company}&site_id=${site}`, config)
            .then(res => {
                const users = res.data.data
                setUsers(users)
            })
    }

    const getSites = async (id) => {
        await axios
            .get(`/api/sites?company_id=${id}`, config)
            .then(res => {
                const sites = res.data
                setSites(sites)
            })
    }

    const getRoles = async () => {
        await axios
            .get(`/api/roles`, config)
            .then(res => {
                const roles = res.data
                setRoles(roles)
            })
    }

    const getAllSites = async () => {
        await axios
            .get(`/api/sites?with=company`, config)
            .then(res => {
                const sites = res.data
                setAllSites(sites)
            })
            .catch(( error ) => {
                if (error.response.status !== 422) {
                    throw error
                }
            })
    }

    const getCurrency = async (cid) => {
        await axios
            .get(`/api/companies/${cid}`, config)
            .then(res => {
                const data = res.data
                setCurrency(data.currency)
                setCurrencyCode(data.currency_code)
                setCurrencySymbol(data.currency.symbol)
                localStorage.setItem('currency_code', data.currency_code)
                localStorage.setItem('currency_symbol', data.currency.symbol)
                localStorage.setItem('currency', JSON.stringify(data.currency))
            })
            .catch(( error ) => {
                if (error.response.status !== 422) {
                    throw error
                }
            })
    }

    const getPalletTypes = async () => {
        await axios
            .get('/api/pallet-types', config)
            .then(res => {
                const data = res.data
                setPalletTypes(data)
            })
            .catch(( error ) => {
                if (error.response.status !== 422) {
                    throw error
                }
            })
    }

    const getFeatures = async () => {
        await axios
            .get('/api/s/features', config)
            .then(res => {
                const data = res.data
                setFeatures(data)
            })
            .catch(( error ) => {
                if (error.response.status !== 422) {
                    throw error
                }
            })
    }

    const getPermissions = async () => {
        await axios
            .get('/api/permissions', config)
            .then(res => {
                const data = res.data
                setPermissions(data)
            })
            .catch(( error ) => {
                if (error.response.status !== 422) {
                    throw error
                }
            })
    }

    const getLogisticsTypes = async () => {
        await axios
            .get('/api/logistics-types', config)
            .then(res => {
                const data = res.data
                setLogisticsTypes(data)
            })
            .catch(( error ) => {
                if (error.response.status !== 422) {
                    throw error
                }
            })
    }

    const getCreditTerms = async () => {
        await axios
            .get('/api/qb-credit-terms', config)
            .then(res => {
                const data = res.data.map((term) => ({
                    id: term.Id,
                    name: term.Name
                }))

                setCreditTerms(data)
            })
            .catch(({ response }) => {
                console.error(response)
            })
    }

    const pusher = new Pusher(process.env.REACT_APP_PUSHER, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        encrypted: true,
    })
    /* Pusher.logToConsole = true */

    return (
        <StateContext.Provider value={{
            pusher, configFile, user, companies, users, setUsers, getUsers,
            config, sites, getSites, roles, allSites, choosesite, setChoosesite,
            company_id, setCompany_id, getCompanies, currency, currencyCode, currencySymbol,
            getCurrency, performedFirstTimeRequestRetrieval, setPerformedFirstTimeRequestRetrieval,
            extended, setExtended, hiddenDailyBoard, setHiddenDailyBoard, hiddenAdminView, setHiddenAdminView,
            hiddenUserManagment, setHiddenUserManagment, hiddenProduction, setHiddenProduction, hiddenInventory,
            setHiddenInventory, total, setTotal, totalPendingProductionOrders, setTotalPendingProductionOrders, menuItems,
            palletTypes, clientType, clientIsBroker, clientIsBrokerOnly, features, permissions, logisticsTypes, creditTerms
        }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)
