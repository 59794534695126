export const COLUMN_NAMES = {
    SHIPMENTS: 'SHIPMENTS',
    COLLECTIONS: 'COLLECTIONS',
    DRIVERS: 'DRIVERS'
}

export const CURRENCIES = {
    currencies: {
        USD: '$',
        EUR: '€',
    }
}

export const DEFAULT_ROUTES = {
    master_admin: "/admin-dashboard",
    office_manager: "/admin-dashboard",
    driver: "/available-loads",
    forklift_driver: "/available-loads",
    dispatcher: "/dispatch",
    production_supervisor: "/production-employees",
    client: "/dashboard",
    salesperson: "/sales-order",
    customers_user: "/shipments-dashboard",
    vendor_user: "/sales-orders"
}

export const REPORT_CODES = {
    collections: {
        COMPLETED_COLLECTIONS_BY_VENDOR: "ccbv",
        ONSITE_COLLECTIONS_AWAITING_UNLOAD: "oscau",
        ONTIME_COLLECTIONS: "otc",
        SITE_COLLECTION_SUMMARY: "scs"
    },
    shipments: {
        COMPLETED_SHIPMENTS: "cs",
        LOADED_OUTBOUND: "lo",
        SITE_SHIPMENT_SUMMARY: "sss",
        ONTIME_DELIVERIES: "otd"
    },
    activities: {
        DRIVER_ACTIVITY_REPORT: "dar",
        DRIVER_PRODUCTIVITY_REPORT: "dpr",
        FORKLIFT_DRIVER_ACTIVITY_REPORT: "fdar",
        FORKLIFT_DRIVER_PRODUCTIVITY_REPORT: "fdpr",
        TRAILER_HISTORY_SUMMARY_BY_VENDOR: "thsbv",
        TRAILER_HISTORY_SUMMARY_BY_CUSTOMER: "thsbc",
        SYSTEM_USAGE_BY_USER: "subu",
    },
    production: {
        PRODUCTION_SUMMARY: "ps",
        PRODUCTION_ACTIVITY_BY_DAY: "pabd",
        PRODUCTION_EMPLOYEE_ACTIVITY_BY_DAY: "peabd",
        PRODUCTION_SUMMARY_REPORT_BY_PRODUCTION_EMPLOYEE: "psrbpe"
    }
}

export const SALES_ORDER_STAUSES = {
    OPEN: 1,
    RELEASED: 2,
    REOPENED: 3,
    ASSIGNED_TO_FORK: 4,
    LOADING: 5,
    LOADED: 6,
    ASSIGNED_TO_DRIVER: 7,
    IN_TRANSIT: 8,
    SHIPPED: 9,
    INVOICED: 10,
    COMPLETED: 11,
    REJECTED: 12,
}

export const PURCHASE_ORDER_STAUSES = {
    OPEN: 1,
    RELEASED: 2,
    REOPENED: 3,
    ASSIGNED_TO_DRIVER: 4,
    IN_TRANSIT: 5,
    ON_SITE: 6,
    ASSIGNED_TO_FORK: 7,
    UNLOADING: 8,
    UNLOADED: 9,
    COMPLETED: 10,
    INVOICED: 11,
    REJECTED: 12,
}

export const TEXTFIELD_STYLE = {
    width: '100%',
    background: '#FCFCFC',
    '.MuiOutlinedInput-input': {
        padding: '9px !important'
    },
    boxShadow: 'none',
    '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid #EEEFF2 !important'
    }
}

export const SELECT_STYLE = {
    width: '100%',
    background: '#FCFCFC',
    boxShadow: 'none',
    '.MuiOutlinedInput-input': {
        padding: '9px !important'
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid #EEEFF2 !important'
    }
}

export const AUTOCOMPLETE_STYLE = {
    width: '100%',
    background: '#FCFCFC',
    boxShadow: 'none',
    '.MuiOutlinedInput-input': {
        padding: '0 !important'
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid #EEEFF2 !important'
    }
}
